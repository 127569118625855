<template>
  <div class="footerBg">
    <div class="footer">
      <div class="center">
        Copyright&copy;2024 &#12288; 山西威士顿信息技术有限公司
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>
<style lang="less" scoped>
.footerBg {
  width: 100%;
  background-size: cover;
  .footer {
    text-align: center;
    padding: 20px 0;
    .center {
      font-size: 14px;
      color: #999;
    }
  }
}
</style>
